<template>
	<div class="community-container">

	</div>
</template>

<script>
import axios from 'axios'
export default {
  metaInfo: {
    title: 'emData 社区',
    meta:
			[
			  {
			    name: 'emData 论坛',
			    content: 'emData bbs论坛'
			  },
			  {
			    name: '鹦鹉塔论坛',
			    content: '鹦鹉塔bbs论坛'
			  },
			  {
			    name: 'emData 社区讨论',
			    content: 'emData 社区讨论'
			  }
			],
    link:
			[
			  {
			    rel: 'emData 论坛',
			    href: 'https://www.emdataer.com/bbs/'
			  }
			]
  },
  data () {
    return {

    }
  },
  mounted () {
    let url = `/version.json?t=${new Date().getTime()}`
    axios.get(url).then(res => {
      if (res.bbsUrl) {
        // 首先创建一个form表单
        var tempForm = document.createElement('form')
        tempForm.id = 'tempForm1'
        // 制定发送请求的方式为post
        tempForm.method = 'post'
        // 此为window.open的url，通过表单的action来实现
        tempForm.action = res.bbsUrl
        // 利用表单的target属性来绑定window.open的一些参数（如设置窗体属性的参数等）
        // tempForm.target="_blank";   //在新界面打开
        const token = localStorage.getItem('eleToken')
        if (token) {
          // 创建input标签，用来设置参数
          var hideInput = document.createElement('input')
          hideInput.type = 'hidden'
          hideInput.name = 'code'
          hideInput.value = token
          // 将input表单放到form表单里
          tempForm.appendChild(hideInput)
          // 将input表单放到form表单里
          tempForm.appendChild(hideInput)
        }
        // 将此form表单添加到页面主体body中
        document.body.appendChild(tempForm)
        // 手动触发，提交表单
        tempForm.submit()
        // 从body中移除form表单
        document.body.removeChild(tempForm)
        // window.open(url, "_blank");
        if (window.history.length <= 1) {
          this.$router.push({ path: '/index' })
          return false
        } else {
          this.$router.push({ path: '/index' })
        }
      } else {
        this.$message({
          type: 'error',
          message: 'bbs 路径无效'
        })
      }
    })
  }
}
</script>

<style scoped lang="less">
	.community-container{
		width: 100%;
		height: calc(100vh - 60px);
		padding: 50px;
		box-sizing: border-box;
		.community-box{
			width: 80%;
			height:calc(100% - 100px);
			margin: auto;
			// background-image: url(../../assets/officialwebsite/community.jpg);
			background-size:100% 100%;
			background-repeat:no-repeat;
		}
	}
</style>
